import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './stripe.css';

const cardElementOptions = {
  style: {
    base: {
      color: '#333',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      backgroundColor: '#fff',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const StripeComponent = () => {
  return (
    <div className="stripe-component">
      <div className="paymentinfo">
        <label style={{ color: '#ddd', fontWeight: 'bold' }}>Payment Information:</label>
        <div className='stripecardelement'>
          <CardElement options={cardElementOptions} />
        </div>
      </div>
    </div>
  );
};

const createPaymentMethod = async (email, stripe, elements) => {
  if (!stripe || !elements) {
    throw new Error('Stripe or elements not loaded');
  }

  const cardElement = elements.getElement(CardElement);
  if (!cardElement) {
    throw new Error('CardElement not found');
  }

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
    billing_details: {
      email: email,
    },
  });

  if (error) {
    console.error(error);
    throw error;
  }

  return paymentMethod;
};

export { StripeComponent, createPaymentMethod };
