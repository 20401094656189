import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import './accountinformation.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PlanSelection from './PlanSelection';
import UpdatePaymentMethod from './UpdatePaymentMethod';

const apiUrl = process.env.REACT_APP_API_URL;
const stripePromise = loadStripe('pk_live_51Pb6jFGYn0swVGLfm9BBYvl1u2ACHeI25SusxqUxtQrovgUM64ldVLWho0AvQIfUPyM36zJn5bfCsJsvbPcciXnd00ZsKllePz');

const AccountInformation = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get('section') || 'accountInfo'; 

  const [userInfo, setUserInfo] = useState({
    email: '',
    FirstName: '',
    LastName: '',
    Subscription: '',
    SubscriptionId: '',
    SubscriptionEnd: '',
    Renew: '',
  });

  const [selectedPlan, setSelectedPlan] = useState('');
  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [currentSection, setCurrentSection] = useState(section); 

  const printRenewalnotice = (userInfo) => {
    let renewal_notice;
    
    if(userInfo.SubscriptionEnd != null && userInfo.SubscriptionId != null){
      const end_date = new Date(userInfo.SubscriptionEnd);
      const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'GMT' };
      const formattedDate = end_date.toLocaleDateString('en-GB', options);

      renewal_notice = "Will ";
      if(userInfo.Renew === 0){
        renewal_notice += "not ";
      }
      renewal_notice += "be renewed on " + formattedDate;
    }
    else{
      renewal_notice = "No active subscription."
    } 
    return renewal_notice;
  };

  const getAccountInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${apiUrl}/account`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      });

      if (response.ok) {
        const resultData = await response.json();
        setUserInfo({
          email: resultData.user,
          FirstName: resultData.FirstName,
          LastName: resultData.LastName,
          Subscription: resultData.Subscription,
          SubscriptionId: resultData.SubscriptionId,
          SubscriptionEnd: resultData.SubscriptionEnd,
          Renew: resultData.Renew
        });
      } else {
        console.error('Failed to fetch account info');
      }
    } catch (error) {
      console.error('Error fetching account info:', error);
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, []);

  const handleFirstNameClick = () => {
    setEditFirstName(true);
    setNewFirstName(userInfo.FirstName);
  };

  const handleLastNameClick = () => {
    setEditLastName(true);
    setNewLastName(userInfo.LastName);
  };

  const handleFirstNameChange = (e) => {
    setNewFirstName(e.target.value);
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      FirstName: e.target.value,
    }));
  };

  const handleLastNameChange = (e) => {
    setNewLastName(e.target.value);
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      LastName: e.target.value,
    }));
  };

  const handleUpdateClick = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${apiUrl}/updateaccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify({
          FirstName: newFirstName,
          LastName: newLastName,
        }),
      });

      if (response.ok) {
        setEditFirstName(false);
        setEditLastName(false);
      } else {
        console.error('Failed to update account info');
      }
    } catch (error) {
      console.error('Error updating account info:', error);
    }
  };

  const renderAccountInfo = () => (
    <div className="account-info-container">
      <h1>Account Information</h1>
      <div className="account-info">
        <div className="info-item">
          <label className = "acctinfolbl">Email/Username:</label>
          <span>{userInfo.email}</span>
        </div>
        <div className="info-item">
          <label className = "acctinfolbl">First Name:</label>
          <div onClick={handleFirstNameClick}>
            <input className="editable-field"
              type="text"
              value={userInfo.FirstName}
              onChange={handleFirstNameChange}
              maxLength="45"
            />
          </div>
        </div>
        <div className="info-item">
          <label className = "acctinfolbl">Last Name:</label>
          <div onClick={handleLastNameClick}>
            <input className="editable-field"
              type="text"
              value={userInfo.LastName}
              onChange={handleLastNameChange}
              maxLength="45"
            />
          </div>
        </div>
        <div className="info-item">
          <label className = "acctinfolbl">Current Subscription:</label>
          <span>{userInfo.Subscription}</span>
        </div>
        <div className="info-item">
          <label className = "acctinfolbl">Renewal Status:</label>
          <span>{printRenewalnotice(userInfo)}</span>
        </div>
      </div>
      <div className='button-container'>
        <button className="update-button" onClick={handleUpdateClick}>Update</button>
      </div>
    </div>
  );

  const renderSubscription = () => (
      <div className="subscription-container">
        <h1>Subscription</h1>
        <Elements stripe={stripePromise}>
          <PlanSelection currentPlan={selectedPlan} onSelectPlan={setSelectedPlan} userInfo={userInfo} />
        </Elements>
      </div>
  );

  const renderUpdatePaymentMethod = () => (
    <div className="update-payment-container">
      <h1>Payment Information</h1>
      <Elements stripe={stripePromise}>
        <UpdatePaymentMethod userInfo={userInfo} />
      </Elements>
    </div>
  );

  return (
    <div className="account-page">
      <div className="top-nav">
        <button onClick={() => setCurrentSection('accountInfo')}>Account Info</button>
        <button onClick={() => setCurrentSection('subscription')}>Subscription</button>
        <button onClick={() => setCurrentSection('updatePayment')}>Payment Method</button>
      </div>

      <div className="sidebar">
        <button onClick={() => setCurrentSection('accountInfo')}>Account Info</button>
        <button onClick={() => setCurrentSection('subscription')}>Subscription</button>
        <button onClick={() => setCurrentSection('updatePayment')}>Payment Method</button>
      </div>

      <div className="content">
        {currentSection === 'accountInfo' && renderAccountInfo()}
        {currentSection === 'subscription' && renderSubscription()}
        {currentSection === 'updatePayment' && renderUpdatePaymentMethod()}
      </div>
    </div>
  );
};

export default AccountInformation;
