import './App.css';
import TextVerification from './components/TextVerification'
import AccountInformation from './components/AccountInformation/AccountInformation.js';
import LoginForm from './components/Login.js';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Outlet, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Header from './components/header.js'; 
import ContactUs from './components/Contactus.js';
import { initGA, logPageView } from './components/analytics';

const apiUrl = process.env.REACT_APP_API_URL;

function AuthGuard() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await fetch(`${apiUrl}/isloggedin`, {
            method: 'GET',
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'application/json'
            },
          });

          if (response.ok) {
            const resultData = await response.json();
            setIsAuthenticated(resultData.isLoggedIn === "True");
          } else {
            setIsAuthenticated(false); 
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setIsAuthenticated(false); 
      }
    };

    checkAuth(); 
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // While checking authentication
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    initGA();
    logPageView();
    window.addEventListener('popstate', logPageView); // Log page views on history changes

    return () => {
      window.removeEventListener('popstate', logPageView);
    };
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <div>
          <Header />
      </div>
      <div>
        <Routes>
        <Route path="/login" element={<LoginForm />} />
          <Route element={<AuthGuard />}>
            <Route path="/account" element={<AccountInformation />} />
            <Route path="/" element={<TextVerification />} />
            <Route path="/contactus" element = {<ContactUs/>} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
