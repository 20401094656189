import React, { useState } from 'react';
import './HamburgerMenu.css'; // Import CSS file
import { useNavigate } from 'react-router-dom';


const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from storage
    setIsOpen(false); // Close the menu after logout
    navigate('/login'); // Optionally redirect to login
  };

  return (
    <div className="hamburger-menu">
      <button className="hamburger-button" onClick={toggleMenu}>
        &#9776; {/* This is the hamburger icon */}
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
      <nav className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/account">Account</a></li>
          <li><a href="/contactus">Contact us</a></li>
          <li onClick={handleLogout}><a href='#'>Logout</a></li> 
        </ul>
      </nav>
    </div>
  );
};
export default HamburgerMenu;
