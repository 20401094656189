import React, { useState, useEffect, useRef } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import './UpdatePaymentMethod.css';
import { StripeComponent, createPaymentMethod } from './stripe';

const apiUrl = process.env.REACT_APP_API_URL;

const UpdatePaymentMethod = ({ userInfo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [last4, setLast4] = useState('');
  const [cardType, setCardType] = useState('');
  const [expiry, setExpiry] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchPaymentMethod = useRef(async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const response = await fetch(`${apiUrl}/payment-method`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (data && data.card) {
        setLast4(data.card.last4);
        const brand = data.card.brand.toLowerCase();
        setCardType(brand);
        setExpiry(`${data.card.exp_month}/${data.card.exp_year.toString().slice(-2)}`);
      }
    } else {
      console.error('Failed to fetch payment method');
    }
  });

  useEffect(() => {
    fetchPaymentMethod.current();
  }, []);

  const handleUpdatePaymentMethod = async (event) => {
    event.preventDefault(); // Prevent form submission

    const token = localStorage.getItem('token');

    if (!stripe || !elements || !token) {
      setMessage('Stripe.js has not loaded properly.');
      return;
    }

    setLoading(true); // Set loading to true before starting the update process

    try {
      const paymentMethod = await createPaymentMethod(userInfo.email, stripe, elements);

      const response_cust = await fetch(`${apiUrl}/is-stripe-customer`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      });
      const result_cust = await response_cust.json();

      if(result_cust.isCustomer === "false"){
        const response = await fetch(`${apiUrl}/get-or-create-customer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
          body: JSON.stringify({
            name: `${userInfo.FirstName} ${userInfo.LastName}`,
            payment_method: paymentMethod ? paymentMethod.id : undefined,
            }),
          });
          
          const result = await response.json();
          if (result.success) {
            fetchPaymentMethod.current(); // Refresh the payment method details
            setMessage('Payment method updated successfully!');
          } else {
            setMessage('Failed to update payment method: ' + result.error);
          }
        }
      else{
          const response = await fetch(`${apiUrl}/update-payment-method`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
            },
          body: JSON.stringify({
            payment_method: paymentMethod.id,
          }),
        });

        const result = await response.json();
        if (result.success) {
          fetchPaymentMethod.current(); // Refresh the payment method details
          setMessage('Payment method updated successfully!');
        } else {
          setMessage('Failed to update payment method: ' + result.error);
        }
      }
      setLoading(false); // Set loading to false after receiving the response
    } catch (error) {
      setMessage('Failed to create payment method.');
      setLoading(false); // Set loading to false if there's an error
    }
  };

  return (
    <div className="payment-info-container">
      {last4 && (
        <div className="existing-card">
          <p className="cardlabel">Current Card:</p>
          <div className="card-details">
            <div className="card-type">{cardType}</div>
            <div className="card-number">**** **** **** {last4}</div>
            <div className="card-expiry">{expiry}</div>
          </div>
        </div>
      )}
      <form className="stripe-form" onSubmit={handleUpdatePaymentMethod}>
        <StripeComponent />
        {message && <p className="message">{message}</p>}
        <div className='button-container'>
          <button type="submit" className="update-payment-button" disabled={loading}>
            {loading ? <span className="spinner"></span> : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePaymentMethod;
