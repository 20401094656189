import React, { useState, useEffect } from 'react';
import './login.css'; // Import CSS file
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
/*global google*/



const clientId = '785685776717-g8q7tjla9kvm9giu60r3fd3rsnblafpt.apps.googleusercontent.com';
const apiUrl = process.env.REACT_APP_API_URL;

const LoginForm = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [RegisterUsername, setRegisterUsername] = useState('');
    const [RegisterPassword, setRegisterPassword] = useState('');
    const [Fname, setFname] = useState('');
    const [Lname, setLname] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [credentialError, setCredentialError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
      const checkGoogle = setInterval(() => {
        if (window.google) {
          clearInterval(checkGoogle);
  
          window.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleGoogleCredentialResponse, // Handle the response directly in this function
            ux_mode: 'popup', // Use popup mode
          });
  
          window.google.accounts.id.renderButton(
            document.getElementById('google-signin-button'),
            { theme: 'outline', size: 'large' }
          );
        }
      }, 75); // Check every 75ms until google is defined
    }, []);

    function handleGoogleCredentialResponse(response) {
      if (response.credential) {
        fetch(`${apiUrl}/google-login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: response.credential }),
        })
          .then(response => {
            // Check if the response status indicates success
            if (response.ok) { 
              return response.json().then(data => {
                const token = response.headers.get('Authorization');
                localStorage.setItem('token', token);
                if(data.message === "Account creation successful"){
                  ReactGA.event({
                    category: 'User',
                    action: 'user_registration',
                    label: 'Registration Form'
                  });
                }
                navigate('/'); // Redirect to the homepage
              });
            } else {
              // Handle non-successful status codes
              return response.json().then(data => {
                console.error('Login failed:', data.error);
                navigate('/login'); // Redirect to the login page on failure
              });
            }
          })
          .catch(error => {
            console.error('Error during login:', error);
            navigate('/login'); // Redirect to the login page on error
          });
      } else {
        console.error('No credential found');
        navigate('/login'); // Redirect to the login page if no token is found
      }
    }
    

  const handleUsernameChange = (e) => {
        setUsername(e.target.value);
  };

  const handleRememberMeChange = (e) => {
    console.log(e.target.checked);
    setRememberMe(e.target.checked);
  };

  const handleRegisterUsernameChange = (e) => {
      setRegisterUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRegisterPasswordChange = (e) => {
    setRegisterPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmpassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(RegisterPassword, e.target.value);
  };
  
  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };

  
  const handleLnameChange = (e) => {
    setLname(e.target.value);
  };

 useEffect(() => {
    const savedUsername = localStorage.getItem('rememberMeUsername');
    if (savedUsername) {
        setUsername(savedUsername);
        setRememberMe(true);
    }
}, []);

  const validateEmail = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!RegisterUsername.match(emailPattern)) {
        setEmailError('Please enter a valid email address.');
        setIsEmailValid(false);
    } else {
        try {
                const response = await fetch(`${apiUrl}/isemailused`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ user: RegisterUsername})
                });
                
                const resultData = await response.json();
                if (resultData.isEmailUsed === "True") {
                  setEmailError('This email is already used.');
                  setIsEmailValid(false);
                }
                else {
                  setEmailError ('');
                  setIsEmailValid(true);
                }
            } catch (error) {
            }
    }
  }

  const validatePassword = (password) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    if (!password.match(passwordPattern)) {
        setPasswordError('Password must be at least 8 characters long and contain both letters and numbers.');
        return false;
    } else {
        setPasswordError('');
        return true;
    }
};

  const checkPasswordMatch = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      setIsPasswordMatch(false);
      setPasswordError('Passwords do not match.');
    } else {
      setIsPasswordMatch(true);
      setPasswordError('');
    }
  };
    
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevents the default form submission
    try {

        if (rememberMe){
          localStorage.setItem("rememberMeUsername", username)
        }
        else{
          localStorage.setItem("rememberMeUsername", "")
        }

        console.log(`${apiUrl}`)
        const response = await fetch(`${apiUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user: username, password:password })
        });
        
        if (!response.ok) {
          const result = await response.json();
          setCredentialError(result.error);
        }
        else{
          const token = response.headers.get('Authorization');
          // Store the token in localStorage
          localStorage.setItem('token', token);
          navigate('/'); // Redirect to the homepage using navigate
        }
    } catch (error) {
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault(); // Prevents the default form submission
    try {
      if (isPasswordMatch && isEmailValid && validatePassword(RegisterPassword)) {
          const response = await fetch(`${apiUrl}/createuser`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ user: RegisterUsername, password:RegisterPassword, firstname:Fname, lastname:Lname })
          });
          
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          ReactGA.event({
            category: 'User',
            action: 'user_registration',
            label: 'Registration Form'
          });
          
          const token = response.headers.get('Authorization');

          // Store the token in localStorage
          localStorage.setItem('token', token);
          window.location.href = '/emailvalidation.html';
        }
    } catch (error) {
    }
  };

  const [action, setAction] = useState('');

  const registerLink = () => {
    setAction('active');
  };

  const loginLink = () => {
    setAction('');
  };


  return (
   <main>
    <div><link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'/></div>
    <div className="logincontainer">
    <div className={`wrapper ${action}`}>
        <div className='form-box login'>
            <form action="" onSubmit={handleLogin}>
                <h1>Log in</h1>
                  <div className='input-box'>
                      <input
                      type="text"
                      id="LoginUsername"
                      name="LoginUsername"
                      placeholder="Email"
                      value={username}
                      onChange={handleUsernameChange}
                      required/>
                      <i className='bx bxs-user'></i>  
                  </div>
                  {credentialError && <span style={{ color: 'red' }}>{credentialError}</span>}
                  <div className='input-box'>
                      <input
                        type="password"
                        id="LoginPassword"
                        name="LoginPassword"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required/>
                       
                        <i className='bx bxs-lock-alt' ></i>  
                   </div>
                    <div className='remember-forgot'>
                      <label><input type="checkbox" 
                                    checked={rememberMe} 
                                    onChange={handleRememberMeChange}/> Remember me
                      </label>
                      <a href='#'>Forgot password?</a>
                    </div>

                    <button type="submit" className='btn'> Log in</button>
                      <div className='register-link'>
                        <p>Don't have an account yet?<a href='#'onClick={registerLink}> Register</a></p>
                      </div>
              </form>
              <hr style={{ margin: '20px 0', border: '2px solid rgba(255, 255, 255, .1)' }} />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>                
                <div id="google-signin-button"></div>
              </div>
          </div>

          <div className='form-box register'>
            <form action="" onSubmit={handleRegister}>
                <h1>Register</h1>
                  <div className='input-box'>
                      <input
                      type="text"
                      id="RegisterUsername"
                      name="RegisterUsername"
                      placeholder="Email"
                      onBlur={validateEmail}
                      value={RegisterUsername}
                      onChange={handleRegisterUsernameChange}
                      required/>
                      <i className='bx bxs-user'></i>                       
                  </div>
                  {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                  <div className='input-box'>
                      <input
                      type="text"
                      id="Fname"
                      name="Fname"
                      value={Fname}
                      onChange={handleFnameChange}
                      placeholder="First name"
                      required/>
                  </div>
                  <div className='input-box'>
                      <input
                      type="text"
                      id="Lname"
                      name="Lname"
                      value={Lname}
                      onChange={handleLnameChange}
                      placeholder="Last name"
                      required/>
                  </div>
                  <div className='input-box'>
                      <input
                        type="password"
                        id="RegisterPassword"
                        name="RegisterPassword"
                        placeholder="Password"
                        value={RegisterPassword}
                        onChange={handleRegisterPasswordChange}
                        required/>
                        <i className='bx bxs-lock-alt' ></i>  
                   </div>
                   {passwordError && <span style={{ color: 'red' }}>{passwordError}</span>}
                   <div className='input-box'>
                      <input
                        type="password"
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                        placeholder="Confirm password"
                        value={confirmpassword}
                        onChange={handleConfirmPasswordChange}
                        required/>
                        <i className='bx bxs-lock-alt' ></i>  

                   </div>
                    <div className='remember-forgot'>
                      <label>
                        <input type='checkbox' required />
                        <a href='T&C.html' target='_blank'>I agree to the Terms & Conditions</a>
                      </label>
                    </div>

                    <button type="submit" className='btn'> Register</button>
                      <div className='register-link'>
                        <p>Already have an account?<a href='#' onClick={loginLink}> Login</a></p>
                      </div>
              </form>
          </div>
        </div>
    </div> 
    </main>
  );
};

export default LoginForm;
