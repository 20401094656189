import React, { useState, useEffect } from 'react';
import './PlanSelection.css';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeComponent, createPaymentMethod } from './stripe';
import ConfirmModal from '../ConfirmModal';

const apiUrl = process.env.REACT_APP_API_URL;

const PlanSelection = ({ currentPlan, onSelectPlan, userInfo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true); // Initially true to indicate loading
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [subscribing, setSubscribing] = useState(false); // Separate state for the subscription process
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const plans = [
    { id: 1, name: 'Basic Plan', price: '9.99 USD/month', analysis: '50', type: 'Monthly' },
    { id: 2, name: 'Premium Plan', price: '19.99 USD/month', analysis: '150', type: 'Monthly' },
    { id: 3, name: 'Pro Plan', price: '29.99 USD/month', analysis: '500', type: 'Monthly' },
  ];

  useEffect(() => {
    const checkPaymentMethod = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false); // Stop loading if no token found
        return;
      }

      const response = await fetch(`${apiUrl}/payment-method`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      });

      const result = await response.json();
      setHasPaymentMethod(result.success);
      setLoading(false); // Stop loading after the backend call returns
    };

    checkPaymentMethod();
    // Set the selected plan based on the current subscription
    if (userInfo.SubscriptionId) {
      onSelectPlan(userInfo.SubscriptionId);
    }
    else{
      onSelectPlan(1);
    }
  }, [userInfo.SubscriptionId]);

  const handleCancelSubClick = () => {
    setIsModalOpen(true);
  };

  const confirmCancelSubscription = async () => {
    console.log("cancelling");
    setIsModalOpen(false);
    const token = localStorage.getItem('token');

    if (!stripe || !elements || !token) {
      setMessage('Stripe.js has not loaded properly.');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/cancel-subscription`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      const cancelation = await response.json();
      setMessage('Subscription canceled successfully.');
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setMessage('Failed to cancel subscription.');
    }
  };

  const handleSubscriptionClick = async () => {
    const token = localStorage.getItem('token');

    if (!stripe || !elements || !token) {
      setMessage('Stripe.js has not loaded properly.');
      return;
    }

    setSubscribing(true); // Set subscribing to true before starting the process

    try {
      let paymentMethod;

      if (!hasPaymentMethod) {
        paymentMethod = await createPaymentMethod(userInfo.email, stripe, elements);

        const response = await fetch(`${apiUrl}/get-or-create-customer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
          body: JSON.stringify({
            name: `${userInfo.FirstName} ${userInfo.LastName}`,
            payment_method: paymentMethod ? paymentMethod.id : undefined,
          }),
        });
        const customer = await response.json();
      }

      const subscriptionResponse = await fetch(`${apiUrl}/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify({
          plan_id: currentPlan,
        }),
      });

      const subscription = await subscriptionResponse.json();
      setSubscribing(false); // Set subscribing to false after the process
      setMessage('Your subscription was updated successfully!');
    } catch (error) {
      console.error('Error creating subscription:', error);
      setMessage('Failed to create subscription.');
      setSubscribing(false); // Set subscribing to false if there's an error
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while fetching payment method
  }

  return (
    <div>
      <div className="plan-selection">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className={`plan-box ${currentPlan === plan.id ? 'selected' : ''}`}
          >
            <h3>{plan.name}</h3>
            <p>{plan.price}</p>
            <p>{plan.analysis} Text Analysis</p>
            <p>{plan.type}</p>
            <button onClick={() => onSelectPlan(plan.id)}>Select</button>
          </div>
        ))}
      </div>
      <div>
        {!hasPaymentMethod && <StripeComponent />}
        <div className='button-container'>
          <button className="cancel-sub-button" onClick={handleCancelSubClick}>Cancel Subscription</button>
          <button className="subscription-button" onClick={handleSubscriptionClick} disabled={subscribing}>
            {subscribing ? <span className="spinner"></span> : 'Subscribe'}
          </button>
        </div>
        {message && <p className="message">{message}</p>}
      </div>
      <ConfirmModal 
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onConfirm={confirmCancelSubscription}
        message="You subscription will be stopped at the end of your period. Are you sure you want to cancel?"
      />
    </div>
  );
};

export default PlanSelection;
