import React from 'react';
import Modal from 'react-modal';
import './ConfirmModal.css';

Modal.setAppElement('#root'); // Ensure accessibility by binding modal to the app root

const ConfirmModal = ({ isOpen, onRequestClose, onConfirm, message }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Confirm Action"
    className="modal"
    overlayClassName="overlay"
  >
    <h2>Confirm Action</h2>
    <p>{message}</p>
    <button onClick={onConfirm}>Yes</button>
    <button onClick={onRequestClose}>No</button>
  </Modal>
);

export default ConfirmModal;
