import React from 'react';
import './header.css'; 
import HamburgerMenu from './HamburgerMenu'; 

const Header = () => {
    return (
        <header className="appheader">
            <HamburgerMenu />
            <div className="applogo">
                <img src="logo.png" alt="AiTextGuard"/>
            </div>
        </header>
    );
};

export default Header;