// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  if (window.location.hostname === 'app.aitextguard.com') {
    ReactGA.initialize('G-TKHPRBVRL1'); // Replace with your GA4 Measurement ID
  }
};

export const logPageView = () => {
  if (window.location.hostname === 'app.aitextguard.com') {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }
};
