import React, { useState } from 'react';
import './Contactus.css';

const apiUrl = process.env.REACT_APP_API_URL;


const ContactUs = () => {
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState('');

  const handleChange = (e) => {
    setFeedback(e.target.value);
  };

  const SubmitFeedback = async () => {
    if(feedback.length > 0){
      try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${apiUrl}/contactus`, {
          method: 'POST',
          headers: {
              'Authorization': `${token}`,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ EnteredText: feedback })
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      else {
        setFeedback('');
      }
      
      const resultData = await response.json();

      setFeedback('');
      setMessage('Your message has been sent successfully.')
      setLoading(false);

    } catch (error) {
      setLoading(false);
    }
  }
  else{
    setMessage('Please enter a message.')
  }
};


  return (
    <div className="contactUs-container">
      <div className="contactUs-form">
        <h1>Contact Us</h1>
        <div className="contactUs-inputBox">
          <textarea
            placeholder="Type your message here..."
            value={feedback}
            onChange={handleChange}
            className="contactUs-textarea"
            required
          />
        </div>
        {message && <p className="message">{message}</p>}
        <div className='button-container'>
          <button type="submit" className="contactUs-button" onClick={SubmitFeedback} disabled={loading}>
          {loading ? <span className="spinner"></span> : 'Submit'} </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
