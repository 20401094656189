import React, { useState, useEffect } from 'react';
import './TextVerification.css'; // Import CSS file
import '../App.css'
import ConfirmModal from './ConfirmModal';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';


const apiUrl = process.env.REACT_APP_API_URL;

function TextVerification() {
    const navigate = useNavigate();
    const [text, setText] = useState('');
    const [isError, setIsError] = useState(false);
    const [verifyClicked, setVerifyClicked] = useState(false);
    const [result, setResult] = useState('');
    const [resultColor, setResultColor] = useState('');
    const [counterColor, setCounterColor] = useState('red'); // State variable for counter color
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [modalMessage, setModalMessage] = useState(''); 
    const minChar = 300;

    const updateCharCount = () => {
        const textLength = text.length;

        if (textLength < minChar) {
            setCounterColor('red');
        } else {
            setCounterColor('white');
        }

        
        if (textLength < minChar && verifyClicked) {
            setResultColor('orange');
        } else {
            setResultColor('');
        }
    }

    const handleInputChange = (event) => {
        setText(event.target.value);
        updateCharCount();
    }

    const verifyText = async () => {
        if (text.length < minChar) {
            setResult("Please enter at least " + minChar + " characters.");
            setResultColor("orange");
            setVerifyClicked(true);
            setIsError(true);
            
            setTimeout(() => {
                setVerifyClicked(false);
                updateCharCount();
                setIsError(false);
            }, 2000);
            
            return;
        }

        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
              throw new Error('No token found');
            }

            const response = await fetch(`${apiUrl}/analyzetext`, {
                method: 'POST',
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ text: text })
            });

            const resultData = await response.json();

            if (!response.ok) {
                
                throw new Error(resultData.error);
            }

            ReactGA.event({
                category: 'User',
                action: 'text_analyzer'
              });

            const resultprobability = parseFloat(resultData.probability).toFixed(2) * 100

            setResult("This text was written by: " + resultData.value + " with a probability of " + resultprobability +"%");
            setResultColor("white");
            setLoading(false);
        } catch (error) {
            setModalMessage(error.message);
            setLoading(false);
            setIsModalOpen(true);
        }
    }

    const handleModalConfirm = () => {
        setIsModalOpen(false);
        navigate('/account?section=subscription'); 
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
    <div className='container'>
        <div className="TextVerification">
            <h1>AI Text Verification</h1>
            <textarea 
                id="inputText" 
                placeholder="Enter text here..."
                value={text}
                onChange={handleInputChange}
                maxLength="1000000"
            />
             <div className={`counter ${isError ? 'error' : ''}`} id="charCount" style={{ color: counterColor }}>
                {text.length}/{minChar}
            </div>
            <div className='button-container'>
                <button className='VerifyButton' onClick={verifyText} disabled={loading}>
                {loading ? <span className="spinner"></span> : 'Verify'} </button>
            </div>
            <div className ="resulttext" id="result" style={{ color: resultColor }}>{result}</div>
        </div>
        <ConfirmModal 
                isOpen={isModalOpen} 
                onRequestClose={handleModalClose} 
                onConfirm={handleModalConfirm} 
                message={modalMessage} // Pass the dynamic message to the modal
        />
    </div>

    );
}

export default TextVerification;
